<div class="d-flex align-items-center" style="min-height: 100vh !important">
  <div class="container bg-white rounded shadow-2 py-3 m-3 mx-sm-auto" style="z-index: 10">
    <div class="row">
      <div class="col-auto text-end d-none d-md-flex align-items-center">
        <img class="w-100" src="assets/common/reset-pwd-form.jpg" alt="reset password image" />
      </div>
      <div class="col">
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-auto">
              <img style="width: 75px" src="assets/logo.png" />
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-12 text-center justify-content-center">
              <div class="col">
                <h4>{{ 'La dernière étape' }}</h4>
                <p class="mt-2">
                  {{ 'Dernière ligne droite ! Choisissez votre mot de passe pour devenir un super contributeur !' }}
                </p>
              </div>
            </div>
          </div>
          <form #f="ngForm" [formGroup]="form" (ngSubmit)="submit()">
            <div class="row mt-4">
              <div class="col">
                <div class="position-relative">
                  <input class="form-control input-with-icon" type="password" [placeholder]="'Mot de passe'" formControlName="password" #p />
                  <div class="input-icon" (click)="p.type === 'password' ? (p.type = 'text') : (p.type = 'password')">
                    <i class="fa-light" [class.fa-eye]="p.type === 'text'" [class.fa-eye-slash]="p.type === 'password'"></i>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col">
                <div class="position-relative">
                  <input
                    class="form-control input-with-icon"
                    type="password"
                    [placeholder]="'Confirmer le mot de passe'"
                    formControlName="passwordConfirm"
                    #p2
                  />
                </div>
                <div class="input-icon" (click)="p2.type === 'password' ? (p2.type = 'text') : (p2.type = 'password')">
                  <i class="fa-light" [class.fa-eye]="p2.type === 'text'" [class.fa-eye-slash]="p2.type === 'password'"></i>
                </div>
              </div>
            </div>
            <div class="row mt-3" *ngIf="error || (f.submitted && !form.valid)">
              <div class="col">
                <div class="errors">
                  <ul class="text-start">
                    <li *ngIf="form.hasError('required', 'password')">{{ 'Le mot de passe est requis' }}</li>
                    <li *ngIf="form.hasError('required', 'passwordConfirm')">
                      {{ 'Vous devez confirmer le mot de passe' }}
                    </li>
                    <li *ngIf="form.hasError('minlength', 'password')">{{ 'Le mot de passe est trop court' }}</li>
                    <li *ngIf="form.hasError('notSame')">{{ 'Les deux mots de passe ne correspondent pas' }}</li>
                    <li *ngIf="error">{{ 'Le lien est expiré (Token invalide)' }}</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col text-center">
                <button class="btn btn-primary text-uppercase w-100" type="submit" [disabled]="loading">
                  {{ 'Valider' }}
                  <i class="fa-light fa-spinner spinning" *ngIf="loading"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
