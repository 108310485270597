// ENTITY fr.jea.metier.user.dto.UserLiteDTO generated by ic2 0.0.1-SNAPSHOT at Sun Nov 26 22:13:25 UTC 2023

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class UserLiteDTO {

  idUser: number | null = 0;
  firstName: string | null = '';
  lastName: string | null = '';
  email: string | null = '';

  constructor(init?:Partial<UserLiteDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.jea.metier.user.dto.UserLiteDTO'] = UserLiteDTO;

reverseMapping[UserLiteDTO.name] = 'fr.jea.metier.user.dto.UserLiteDTO';

fields['fr.jea.metier.user.dto.UserLiteDTO']  = {
    idUser: 'java.lang.Integer',
    firstName: 'java.lang.String',
    lastName: 'java.lang.String',
    email: 'java.lang.String'
};
