<div class="d-flex align-items-center" style="min-height: 100vh !important">
  <div class="container bg-white rounded shadow-2 py-3 m-3 mx-sm-auto" style="z-index: 10">
    <div class="row">
      <div class="col-auto text-end d-none d-md-flex align-items-center">
        <img src="assets/common/reset-password-img.jpg" />
      </div>
      <div class="col">
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-auto">
              <img style="width: 75px" src="assets/logo.png" />
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-12 text-center justify-content-center">
              <h4>
                {{ "C'est tout bon !" }}
                <br />
              </h4>
              <p class="mt-2">{{ 'Surveillez votre boîte mail et revenez vite vous connecter!' }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
