<div class="row justify-content-center mt-3">
  <div class="col col-md-10 col-lg-8">
    <div class="bg-white border p-3">
      <ng-container *ngIf="!editMode">
        <div class="row align-items-center mt-2">
          <div class="col my-fs-5">
            <i class="fa-light fa-file-pen me-2"></i>
            {{ niveau.name }}
          </div>

          <div class="col-auto my-fs-4" *ngIf="getNbPlacesRestantes() > 0">{{ getNbPlacesRestantes() }} place(s) restante(s) sur {{ niveau.nbSlots }}</div>
          <div class="col-auto my-fs-4" *ngIf="getNbPlacesRestantes() <= 0">
            {{ niveau.nbSlots }} place(s)
            <div class="text-danger">COMPLET</div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="editMode">
        <div class="row mt-2">
          <div class="col">
            <input class="form-control" name="niveau-name-{{ index }}" type="text" placeholder="Nouveau niveau" [(ngModel)]="niveau.name" required />
          </div>
          <div class="col-auto">
            <button class="btn btn-danger" type="button" (click)="deleteNiveau.emit()">
              <i class="fa-light fa-trash"></i>
            </button>
          </div>
        </div>
        <div class="row mt-3 align-items-center" *ngIf="admin">
          <div class="col">order (admin)</div>
          <div class="col">
            <input class="form-control" name="niveau-order-{{ index }}" type="number" [(ngModel)]="niveau.order" />
          </div>
        </div>

        <div class="row mt-3">
          <div class="col">Nombre de places</div>
        </div>
        <div class="row align-items-baseline mt-2">
          <div class="col">Participants</div>
          <div class="col">
            <input class="form-control" name="niveau-nbSlots-{{ index }}" type="number" [(ngModel)]="niveau.nbSlots" required />
          </div>
          <div class="col">Correcteurs</div>
          <div class="col">
            <input class="form-control" name="niveau-nbCorrecteurSlots-{{ index }}" type="number" [(ngModel)]="niveau.nbCorrecteurSlots" required />
          </div>
        </div>
      </ng-container>
      <div class="row">
        <div class="col"><hr /></div>
      </div>
      <div class="row">
        <div class="col"><b>Correcteurs</b></div>
      </div>
      <div class="row" *ngIf="getCorrecteurs().length === 0">
        <div class="col">Aucun participant pour le moment</div>
      </div>
      <div class="row" *ngFor="let correcteur of getCorrecteurs()">
        <div class="col">
          <i class="fa-light fa-user-circle me-2"></i>
          {{ getUser(correcteur.idUser).firstName + ' ' + getUser(correcteur.idUser).lastName }}
          <span class="my-fs-1 pointer" (click)="copy(getUser(correcteur.idUser).email)" *ngIf="correcteur">({{ getUser(correcteur.idUser).email }})</span>
        </div>
        <div class="col-auto" *ngIf="canDeleteCorrecteur(correcteur.idUser)">
          <i class="fa-light fa-trash text-danger-hover" (click)="deleteCorrecteur(correcteur.idUser)"></i>
        </div>
      </div>
      <div class="row">
        <div class="col"><hr /></div>
      </div>
      <div class="row">
        <div class="col"><b>Participants</b></div>
      </div>
      <div class="row" *ngIf="getParticipants().length === 0">
        <div class="col">Aucun participant pour le moment</div>
      </div>
      <div
        class="row align-items-center py-2"
        [class.jea-bg-inscrit]="participant.state === ParticipationState.INSCRIT"
        [class.jea-bg-trame-envoye]="participant.state === ParticipationState.TRAME_ENVOYE"
        [class.jea-bg-envoye]="participant.state === ParticipationState.ENVOYE"
        [class.jea-bg-correction]="participant.state === ParticipationState.CORRECTION"
        [class.jea-bg-valide]="participant.state === ParticipationState.VALIDE"
        [class.jea-bg-abandon]="participant.state === ParticipationState.ABANDON"
        *ngFor="let participant of getParticipants() | orderBy : 'numFiche'"
      >
        <div class="col">
          <i class="fa-light fa-user-circle me-2"></i>
          <i class="fa-light fa-ship me-2" *ngIf="participant.etaitPretAEmbarquer"></i>
          {{ getUser(participant.idUser).firstName + ' ' + getUser(participant.idUser).lastName }}
          <span class="my-fs-1 pointer" (click)="copy(getUser(participant.idUser).email)" *ngIf="correcteur">({{ getUser(participant.idUser).email }})</span>
        </div>
        <div class="col-auto">Fiche N°{{ participant.numFiche }}</div>
        <div
          class="col-auto"
          [class.jea-text-inscrit]="participant.state === ParticipationState.INSCRIT"
          [class.jea-text-trame-envoye]="participant.state === ParticipationState.TRAME_ENVOYE"
          [class.jea-text-envoye]="participant.state === ParticipationState.ENVOYE"
          [class.jea-text-correction]="participant.state === ParticipationState.CORRECTION"
          [class.jea-text-valide]="participant.state === ParticipationState.VALIDE"
          [class.jea-text-abandon]="participant.state === ParticipationState.ABANDON"
          *ngIf="!canUpdateState()"
        >
          {{ participant.state.lib }}
        </div>
        <div class="col-auto" *ngIf="canUpdateState()">
          <ic2-select
            [allowNull]="false"
            [values]="ParticipationState.values"
            [ngModel]="participant.state"
            (ngModelChange)="stateChange(participant, $event)"
          ></ic2-select>
        </div>
      </div>
      <div class="row justify-content-end mt-3" *ngIf="!editMode">
        <div class="col-auto mt-2" *ngIf="inscriptionPossible()">
          <button class="btn btn-secondary" type="button" (click)="inscriptionParticipant.emit()">S'inscrire</button>
        </div>
        <div class="col-auto mt-2" *ngIf="inscriptionCorrecteurPossible()">
          <button class="btn btn-secondary" type="button" (click)="inscriptionCorrecteur.emit()">S'inscrire en tant que correcteur</button>
        </div>
      </div>
    </div>
  </div>
</div>
