// ENTITY fr.jea.metier.jea.entities.AtelierNiveau generated by ic2 0.0.1-SNAPSHOT at Sun Nov 26 22:13:25 UTC 2023

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class AtelierNiveau {

  idAtelierNiveau: number | null = 0;
  idAtelier: number | null = 0;
  name: string | null = '';
  order: number | null = 0;
  nbSlots: number | null = 0;
  nbCorrecteurSlots: number | null = 0;
  del: boolean | null = false;

  constructor(init?:Partial<AtelierNiveau>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.jea.metier.jea.entities.AtelierNiveau'] = AtelierNiveau;

reverseMapping[AtelierNiveau.name] = 'fr.jea.metier.jea.entities.AtelierNiveau';

fields['fr.jea.metier.jea.entities.AtelierNiveau']  = {
    idAtelierNiveau: 'java.lang.Integer',
    idAtelier: 'java.lang.Integer',
    name: 'java.lang.String',
    order: 'java.lang.Integer',
    nbSlots: 'java.lang.Integer',
    nbCorrecteurSlots: 'java.lang.Integer',
    del: 'java.lang.Boolean'
};
