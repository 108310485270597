<div class="d-flex align-items-center" style="min-height: 100vh !important">
  <div class="container bg-white rounded shadow-2 py-3 m-3 mx-sm-auto" style="z-index: 10">
    <!--<div style="width: 23rem"></div>-->

    <!--logo-->
    <div class="row justify-content-center">
      <div class="col-auto">
        <img style="width: 75px" src="assets/logo.png" />
      </div>
    </div>

    <!--form-->
    <div class="row mt-3">
      <div class="col-12 col-md-6 line">
        <form #f="ngForm" [formGroup]="form" (ngSubmit)="submit()">
          <div class="container-fluid">
            <div class="row mt-4">
              <div class="col-12 text-center justify-content-center">
                <div class="text-left">
                  <h3>{{ 'Bienvenue dans votre espace Jouer et Apprendre' }}</h3>
                </div>
                <div class="text-left mt-2">{{ 'Connectez vous pour accéder à toutes vos données' }}</div>
              </div>
            </div>

            <div class="row mt-4 justify-content-center">
              <div class="col-12 col-md-8">
                <div class="form-group" style="margin-bottom: 2rem">
                  <input class="form-control" type="email" [placeholder]="'E-mail'" formControlName="email" />
                </div>
              </div>
            </div>

            <div class="row justify-content-center">
              <div class="col-12 col-md-8">
                <div class="input-group">
                  <input class="form-control" type="password" autocomplete="current-password" [placeholder]="'Mot de passe'" formControlName="password" #p />
                  <div class="input-group-text" (click)="p.type === 'password' ? (p.type = 'text') : (p.type = 'password')">
                    <i class="fa-light" [class.fa-eye]="p.type === 'text'" [class.fa-eye-slash]="p.type === 'password'"></i>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-1 justify-content-center">
              <div class="col-12 col-md-8 text-end">
                <a class="fw-400 my-fs-2 pointer" routerLink="/reset-password">{{ 'Mot de passe oublié' }}</a>
              </div>
            </div>

            <!--Errors handling-->
            <div class="row justify-content-center mt-4" *ngIf="f.submitted && (!form.valid || error)">
              <div class="col-12 col-md-8">
                <ul class="errors">
                  <li *ngIf="form.hasError('required', 'email')">{{ "L'E-mail est requis" }}</li>
                  <li *ngIf="form.hasError('email', 'email')">{{ "L'E-mail est invalide" }}</li>
                  <li *ngIf="form.hasError('required', 'password')">{{ 'Le mot de passe est requis' }}</li>
                  <li *ngIf="form.hasError('minlength', 'password')">{{ 'Le mot de passe est trop court' }}</li>
                  <li *ngIf="error">{{ error }}</li>
                </ul>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col text-center">
                <button class="btn btn-primary text-uppercase" type="submit" [disabled]="loading">
                  {{ 'Me connecter' }}
                  <i class="fa-light fa-spinner spinning" *ngIf="loading"></i>
                </button>
              </div>
            </div>
          </div>
        </form>

        <div class="row mt-3 justify-content-center d-md-none">
          <div class="col-9">
            <hr />
          </div>
        </div>
      </div>

      <!-- Première connexion-->
      <div class="col-12 col-md-6">
        <div class="container-fluid">
          <div class="row mt-4 text-center justify-content-center">
            <div class="col-auto">
              <div>
                <h3>{{ "C'est votre première connexion ?" }}</h3>
              </div>
              <div class="mt-2">{{ 'Créez vite votre compte pour commencer à utiliser la plateforme Jouer et Apprendre !' }}</div>
            </div>
          </div>
          <div class="row text-center justify-content-center-5 mt-4">
            <div class="col">
              <a class="btn btn-primary text-uppercase" routerLink="/signup">{{ 'Créer mon compte' }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--bottom-wave-->
  </div>
  <ic2-toastr-container class="position-fixed bottom-0 start-50 translate-middle-x"></ic2-toastr-container>
</div>
