import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { IRPC, L } from '@ic2/ic2-lib';
import { Observable, Subject, Subscription } from 'rxjs';
import { JEARight } from 'src/ic2/entities/JEARight';
import { UserBundle } from 'src/ic2/entities/UserBundle';
import { CoreService } from 'src/ic2/services/CoreService';
import { FacebookLoginService } from 'src/ic2/services/FacebookLoginService';
import { Ic2ToastrService } from './ic2-toastr.service';

export class LoginEvent {
  userBundle: UserBundle;
  permitLogin: boolean = true;
  redirectUrl: string = null;
  onLoginAction: () => Promise<any | any[]>;
}
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userBundle: UserBundle = null;
  // store the URL so we can redirect after logging in
  loginEvent: Subject<LoginEvent> = new Subject<LoginEvent>();
  logoutEvent: Subject<void> = new Subject<void>();
  getBundleSubscription: Subscription = null;

  static has(bundle: UserBundle, ...roles: JEARight[]) {
    if (!bundle) return false;
    for (const role of roles) {
      for (const possessedRole of bundle.rights) {
        if (role.id === possessedRole.idRight) return true;
      }
    }
    return false;
  }

  constructor(
    private router: Router,
    public coreService: CoreService,
    public irpc: IRPC,
    private ic2ToastrService: Ic2ToastrService,
    private facebookLoginService: FacebookLoginService
  ) {}

  isLoggedIn(): Observable<boolean> {
    return new Observable((observer) => {
      if (this.getBundleSubscription !== null) {
        console.log('already connecting, adding to bundle subscription');
        this.getBundleSubscription.add(() => {
          if (this.userBundle !== undefined && this.userBundle !== null) observer.next(true);
          else observer.next(false);
          observer.complete();
        });
        return;
      }

      //console.log('COOKIES :: ', this.cookiesService.getAll());
      const token = this.getToken();
      //const token = this.cookieService.get('token');
      //console.log(token);
      if (token === null || token === undefined || token === 'undefined' || token === 'null') {
        console.log('token not found');
        observer.next(false);
        observer.complete();
        return;
      }
      this.irpc.authToken = token;

      //check que le token est tjr valable
      const decodedToken = new JwtHelperService().decodeToken(token);
      //console.log('decoded', decodedToken);
      if (decodedToken && decodedToken.exp) {
        const expires = new Date(decodedToken.exp * 1000);
        const compareTo = new Date(new Date().getTime() + 30 * 60000);
        if (compareTo > expires) {
          console.log('token expired');
          this.irpc.authToken = null;
          observer.next(false);
          observer.complete();
          return;
        }
      }
      //removed else block because token now hos no expiration
      /*else {
        this.irpc.authToken = null;
        observer.next(false);
        observer.complete();
        return;
      }*/

      if (this.userBundle !== undefined && this.userBundle !== null) {
        observer.next(true);
        observer.complete();
        return;
      }

      //Recuperer le bundle
      this.getBundleSubscription = this.coreService.getBundle().subscribe(
        (data) => {
          const event = new LoginEvent();
          event.userBundle = data;
          event.redirectUrl = null;
          this.loginEvent.next(event);
          if (event.permitLogin) {
            this.bundleReceived(data);
            this.getBundleSubscription = null;
            if (event.redirectUrl !== null) {
              console.log('bundle auto redirect to ' + event.redirectUrl);
              this.router.navigate([event.redirectUrl]);
            }
            if (event.onLoginAction) {
              console.log('exec onLoginAction ', event);
              event.onLoginAction().then(() => {
                console.log('exec onLoginAction done');
                observer.next(true);
                observer.complete();
              });
            } else {
              console.log('getBundle complete ', event);
              observer.next(true);
              observer.complete();
            }
          } else {
            L.v('AuthService', 'Login aborted because of the observer');
          }
        },
        (error) => {
          this.getBundleSubscription = null;
          console.error(error);
          observer.next(false);
          observer.complete();
          this.logout(); //logout on error to clean token
        }
      );
    });
  }

  loginWith(data: UserBundle, onLoginAction: () => void = null, useRedirectUrl: boolean = true) {
    const event = new LoginEvent();
    event.userBundle = data;
    event.redirectUrl = null;
    this.loginEvent.next(event);
    if (event.permitLogin) {
      this.bundleReceived(data);
      if (event.onLoginAction) {
        console.log('exec event onLoginAction ', event);
        event.onLoginAction().then(() => {
          console.log('exec event onLoginAction done');
          console.log('exec local onLoginAction');
          if (onLoginAction) onLoginAction();
          console.log('exec local onLoginAction done');
          if (event.redirectUrl !== null) {
            console.log('loginWith auto redirect to ' + event.redirectUrl);
            this.router.navigate([event.redirectUrl]);
          }
        });
      } else {
        console.log('exec local onLoginAction ', event);
        if (onLoginAction) onLoginAction();
        console.log('exec local onLoginAction done');
        console.log(event, useRedirectUrl);
        if (event.redirectUrl !== null && useRedirectUrl) {
          console.log('loginWith auto redirect to ' + event.redirectUrl);
          this.router.navigate([event.redirectUrl]);
        }
      }
    } else {
      L.v('AuthService', 'Login aborted because of the observer');
    }
  }

  private bundleReceived(data: UserBundle) {
    this.userBundle = data;
    this.saveToken(data.token);
    //this.cookieService.put('token', data.token);
  }

  public getToken() {
    //return this.cookieService.get('token');
    return localStorage.getItem('token');
  }

  public saveToken(token: string) {
    if (token === null || token === undefined) return;
    this.irpc.authToken = token;
    localStorage.setItem('token', token);
    //this.cookieService.put('token', data.token);
    //console.log('token saved', token);
  }

  loginWithFBToken(accessToken: string): Observable<LoginEvent> {
    return new Observable((observer) => {
      this.facebookLoginService.loginWithFacebook(accessToken).subscribe(
        (data) => {
          const event = new LoginEvent();
          event.userBundle = data.ub;
          event.redirectUrl = '/';
          if (localStorage.getItem('redirectUrl') !== null) {
            event.redirectUrl = localStorage.getItem('redirectUrl');
            localStorage.removeItem('redirectUrl');
          }
          observer.next(event);
          observer.complete();
          this.loginEvent.next(event);
          if (event.permitLogin) {
            this.bundleReceived(data.ub);

            if (event.onLoginAction) {
              console.log('exec event onLoginAction ', event);
              event.onLoginAction().then(() => {
                console.log('exec event onLoginAction done');
                if (event.redirectUrl !== null) {
                  console.log('login auto redirect to ' + event.redirectUrl);
                  this.router.navigate([event.redirectUrl]);
                }
              });
            } else {
              if (event.redirectUrl !== null) {
                console.log('login auto redirect to ' + event.redirectUrl);
                this.router.navigate([event.redirectUrl]);
              }
            }
          } else {
            L.v('AuthService', 'Login aborted because of the observer');
          }
        },
        (error) => {
          //console.error(error);
          observer.error(error);
        }
      );
    });
  }

  login(email: string, password: string): Observable<LoginEvent> {
    return new Observable((observer) => {
      this.coreService.login(email, password).subscribe(
        (data) => {
          const event = new LoginEvent();
          event.userBundle = data.ub;
          if (localStorage.getItem('redirectUrl') !== null) {
            event.redirectUrl = localStorage.getItem('redirectUrl');
            localStorage.removeItem('redirectUrl');
          }
          observer.next(event);
          observer.complete();
          this.loginEvent.next(event);
          if (event.permitLogin) {
            this.bundleReceived(data.ub);

            if (event.onLoginAction) {
              console.log('exec event onLoginAction ', event);
              event.onLoginAction().then(() => {
                console.log('exec event onLoginAction done');
                if (event.redirectUrl !== null) {
                  console.log('login auto redirect to ' + event.redirectUrl);
                  this.router.navigate([event.redirectUrl]);
                }
              });
            } else {
              if (event.redirectUrl !== null) {
                console.log('login auto redirect to ' + event.redirectUrl);
                this.router.navigate([event.redirectUrl]);
              }
            }
          } else {
            L.v('AuthService', 'Login aborted because of the observer');
          }
        },
        (error) => {
          //console.error(error);
          observer.error(error);
        }
      );
    });
  }

  has(...roles: JEARight[]) {
    return AuthService.has(this.userBundle, ...roles);
  }

  logout(): void {
    localStorage.removeItem('token');
    //this.cookieService.remove('token');
    this.irpc.authToken = null;
    this.userBundle = null;
    //('logged out');
    this.logoutEvent.next();
    //this.router.navigate(['connexion']);
    /*if (window['FB']) {
      window['FB'].logout(() => {});
    }*/
  }
}
