import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { EnvService, IRPC, L, RpcError } from '@ic2/ic2-lib';
import { jeaVersion } from 'src/environments/version';
import { AuthGuard } from './tools/auth-guard.service';
import { AuthService } from './tools/auth.service';
import { Config } from './tools/Config';
import { Ic2ToastrService } from './tools/ic2-toastr.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    public irpc: IRPC,
    router: Router,
    private authService: AuthService,
    authGuard: AuthGuard,
    ic2ToastrService: Ic2ToastrService,
    env: EnvService<Config>
  ) {
    irpc.url = env.configuration.rpcHost + '/rpc';
    if (env.configuration.name === 'DEV') {
      irpc.url = 'http://' + window.location.hostname + ':8080/rpc';
    }
    if (this.irpc.authToken === null || this.irpc.authToken === undefined) {
      const token = authService.getToken();
      //console.log(token);
      if (token) {
        this.irpc.authToken = token;
      }
    }
    irpc.log = env.configuration.logRpcRequests;
    irpc.version = jeaVersion.fullHash;

    irpc.defaultOnError = (err) => {
      L.e(err);
      ic2ToastrService.show('Une erreur est survenue', { classname: 'bg-danger text-white' });
    };

    irpc.interceptErrorHandlers.push((req, err) => {
      if (err.code === RpcError.NOT_LOGGED) {
        console.log('INTERCEPTED NOT LOGGED ERROR on ' + req.req.serviceDotMethod);
        authService.logout();
        router.navigate(['/login']);
        return true;
      }
      if (err.isVersionError()) {
        if (router.url !== '/update') localStorage.setItem('redirectUrl', router.url);
        router.navigate(['/update']);
        return true;
      }
      if (!err.isBusinessError() && authService.userBundle !== null) {
        ic2ToastrService.show('Une erreur est survenue', { classname: 'bg-danger text-white' });
      }
      return false;
    });
    authGuard.redirectLoginRoute = '/login';

    //ic2ToastrService.show('test', { classname: 'bg-danger text-white', delay: 9999999 });

    authService.loginEvent.subscribe((login) => {
      console.log('setting up login action');
      login.onLoginAction = () => Promise.all([]);
    });
  }
}
