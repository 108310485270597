// DB ENUM fr.jea.metier.JEARight generated by ic2 0.0.1-SNAPSHOT at Sun Nov 26 22:13:25 UTC 2023

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class JEARight {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly ADMIN = new JEARight(1, "Admin", "ADMIN");
  static readonly CREATEUR = new JEARight(2, "Créateur", "CREATEUR");
  static readonly CORRECTEUR = new JEARight(3, "Correcteur", "CORRECTEUR");

  static readonly values = [
    JEARight.ADMIN,
 
    JEARight.CREATEUR,
 
    JEARight.CORRECTEUR
 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of JEARight.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of JEARight.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['fr.jea.metier.JEARight'] = JEARight;
reverseMapping[JEARight.name] = 'fr.jea.metier.JEARight';
