<div class="wrapper">
  <aside [class.show]="show">
    <div class="d-flex align-items-center bg-header p-1 pt-4 pb-3">
      <div class="ms-2">
        <h5 class="mt-2">{{ authService.userBundle.user.firstName + ' ' + authService.userBundle.user.lastName }}</h5>
        <p *ngIf="authService.userBundle.rights.length > 0">{{ JEARight.mapFromId(authService.userBundle.rights[0].idRight).lib }}</p>
      </div>
    </div>
    <nav>
      <h4 class="border-bottom border-white fw-normal">Menu</h4>
      <div class="d-flex flex-column">
        <a class="item" routerLinkActive="active" routerLink="/" [routerLinkActiveOptions]="{ exact: true }" (click)="hideMenu()">
          <div class="icon"><i class="fa-light fa-home"></i></div>
          <div>Accueil</div>
        </a>
        <a
          class="item"
          routerLinkActive="active"
          routerLink="/atelier/add"
          [routerLinkActiveOptions]="{ exact: true }"
          (click)="hideMenu()"
          *ngIf="canAddAtelier"
        >
          <div class="icon"><i class="fa-light fa-plus"></i></div>
          <div>Atelier</div>
        </a>
        <a class="mini-item pointer d-flex" (click)="logout()">
          <div class="icon"><i class="fa-light fa-sign-out-alt"></i></div>
          {{ 'Déconnexion' }}
        </a>
      </div>
    </nav>
  </aside>
  <div class="d-flex flex-column flex-grow-1">
    <div class="menu-button text-white" (click)="toggleMenu()">
      <i class="fa-light fa-bars"></i>
    </div>
    <header>
      <div class="back-link d-flex align-items-center h-100 text-white ps-2 pe-2 pointer user-select-none" [class.visible]="showBackLink" back>
        <i class="fa-light fa-chevron-left me-lg-2"></i>
        <span class="d-none d-lg-inline">Retour</span>
      </div>
      <h3>
        <i [ngClass]="'me-2 ' + titleIconClass" *ngIf="titleIconClass !== null"></i>
        <span>{{ title }}</span>
      </h3>
      <div class="d-flex align-items-center">
        <div class="theme-button pointer px-2 py-1 me-2" (click)="toggleDarkMode()">
          <i class="fa-light" [class.fa-moon]="isDarkMode" [class.fa-sun]="!isDarkMode"></i>
        </div>
      </div>
    </header>
    <main (click)="hideMenu()">
      <router-outlet></router-outlet>
      <ic2-toastr-container class="position-fixed bottom-0 start-50 translate-middle-x"></ic2-toastr-container>
    </main>
  </div>
</div>
