/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { registerLocaleData } from '@angular/common';
import localefr from '@angular/common/locales/fr';

registerLocaleData(localefr);

import './ic2/entities/entities';
import './ic2/services/services';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => {
    let message = '';

    const loadingElement = document.querySelector('#LoadingElement') as HTMLElement;
    const errorMsgElement = document.querySelector('#errorMsgElement') as HTMLElement;
    const refreshButton = document.querySelector('#refreshButton') as HTMLButtonElement;

    loadingElement.style.display = 'none';

    if (err.message == 'Time') message = 'Loading in progress, please hang tight';
    else if (err.message == 'Down') message = 'An error occurred, please try again later';

    errorMsgElement.textContent = message;
    refreshButton.style.display = 'block';
  });
