import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { L } from '@ic2/ic2-lib';
import { RegisterService } from 'src/ic2/services/RegisterService';

@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.scss'],
})
export class ConfirmEmailComponent implements OnInit {
  token: string = null;
  loading: boolean = false;
  error: string = null;

  constructor(private activatedRoute: ActivatedRoute, private registerService: RegisterService) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      if (params['token'] === undefined) return;
      this.token = params['token'];
      this.registerService
        .confirmEmail(this.token)
        .onError((err) => {
          this.error = err.message;
          L.e(err);
        })
        .execute((data) => {
          this.loading = false;
        });
    });
  }
}
