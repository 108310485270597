import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { L, UserEditValidators } from '@ic2/ic2-lib';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/tools/auth.service';
import { EmailAvailability } from 'src/ic2/entities/EmailAvailability';
import { RegisterService } from 'src/ic2/services/RegisterService';
import { DataProcessingModalComponent } from '../data-processing-modal/data-processing-modal.component';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  formMail: FormGroup = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
  });
  formUser: FormGroup = this.fb.group(
    {
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      password: [null, [Validators.required, Validators.minLength(6)]],
      confirmPassword: [null, [Validators.required, Validators.minLength(6)]],
    },
    {
      validator: UserEditValidators.checkPasswords('password', 'confirmPassword'),
    }
  );
  readonly STEP_1 = 1;
  readonly STEP_CREATE = 2;
  step: number = this.STEP_1;
  loading: boolean = false;
  loginError: string;

  constructor(
    @Inject(FormBuilder) private fb: FormBuilder,
    private registerService: RegisterService,
    private authService: AuthService,
    private router: Router,
    private ngbModal: NgbModal
  ) {}

  ngOnInit(): void {}

  onSubmitEmail() {
    if (!this.formMail.valid) {
      return;
    }
    if (this.loading) return;
    this.loading = true;
    this.registerService.emailAvailable(this.formMail.value.email).subscribe(
      (data) => {
        if (data === EmailAvailability.NOT_AVAILABLE) {
          this.loginError = "L'Email existe déjà";
          this.loading = false;
        } else if (data === EmailAvailability.ACCOUNT_CREATION) {
          this.step = this.STEP_CREATE;
          this.loading = false;
        }
      },
      (err) => {
        this.loading = false;
        L.e(err);
        this.loginError = "Impossible de vérifier si l'email est disponible";
      }
    );
  }

  onSubmitUser() {
    if (!this.formUser.valid) {
      return;
    }
    if (this.loading) return;
    this.loading = true;
    this.registerService
      .register(this.formUser.value.firstName, this.formUser.value.lastName, this.formMail.value.email, this.formUser.value.password)
      .subscribe(
        (data) => {
          this.loading = false;
          this.authService.loginWith(data.ub, () => {
            this.router.navigate(['/']);
          });
        },
        (err) => {
          this.loading = false;
          L.e(err);
        }
      );
  }

  showPopup() {
    this.ngbModal.open(DataProcessingModalComponent, { centered: true });
  }
}
