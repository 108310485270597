import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/tools/auth.service';
import { DateUtils } from 'src/app/tools/DateUtils';
import { Ic2ToastrService } from 'src/app/tools/ic2-toastr.service';
import { AtelierDTO } from 'src/ic2/entities/AtelierDTO';
import { AtelierNiveau } from 'src/ic2/entities/AtelierNiveau';
import { AtelierParticipation } from 'src/ic2/entities/AtelierParticipation';
import { JEARight } from 'src/ic2/entities/JEARight';
import { ParticipationState } from 'src/ic2/entities/ParticipationState';
import { ParticipationService } from 'src/ic2/services/ParticipationService';
import { AtelierComponent } from '../atelier.component';

@Component({
  selector: 'app-pret-a-embarquer',
  templateUrl: './pret-a-embarquer.component.html',
  styleUrls: ['./pret-a-embarquer.component.scss'],
})
export class PretAEmbarquerComponent {
  @Input() data: AtelierDTO;
  @Input() editMode: boolean;
  @Output() inscriptionParticipant = new EventEmitter<void>();
  correcteur: boolean = false;
  numFiche: number = null;
  niveau: AtelierNiveau = null;

  presentNiveau = (niveau: AtelierNiveau) => niveau.name;

  constructor(
    private authService: AuthService,
    private ic2ToastrService: Ic2ToastrService,
    private atelierComponent: AtelierComponent,
    private participationService: ParticipationService,
    private ngbModal: NgbModal
  ) {
    this.correcteur = authService.has(JEARight.ADMIN, JEARight.CREATEUR, JEARight.CORRECTEUR);
  }

  getNbPlacesRestantesPretAEmbarquer() {
    return this.data.atelier.nbSlotPretAEmbarquer - this.getParticipantsPretAEmbarquer().length;
  }

  getParticipantsPretAEmbarquer() {
    return this.data.participations.filter((p) => p.etaitPretAEmbarquer);
  }

  inscriptionPretAEmbarquerPossible() {
    return (
      !this.data.participations.some((p) => p.idUser === this.authService.userBundle.user.idUser) && //on a aucune participation
      DateUtils.isPastOrNow(this.data.atelier.dispoDate) &&
      this.getNbPlacesRestantesPretAEmbarquer() > 0 &&
      this.atelierComponent.getNbPlacesRestantes() <= 0
    );
  }

  getUser(idUser: number) {
    return this.data.users.find((u) => u.idUser === idUser);
  }

  copy(str: string) {
    navigator.clipboard.writeText(str);
    this.ic2ToastrService.show('"' + str + '" copié dans le presse-papier', { classname: 'bg-success text-white' });
  }

  getNiveaux() {
    if (
      this.data.atelier.idUserCreator === this.authService.userBundle.user.idUser ||
      this.data.createurs.some((c) => c.idUser === this.authService.userBundle.user.idUser)
    )
      return this.data.niveaux;
    else
      return this.data.niveaux.filter((n) =>
        this.data.correcteurs.some((c) => c.idUser === this.authService.userBundle.user.idUser && c.idAtelierNiveau === n.idAtelierNiveau)
      );
  }

  canUpdateState() {
    return (
      this.data.atelier.idAtelier !== 0 &&
      this.correcteur &&
      (this.data.atelier.idUserCreator === this.authService.userBundle.user.idUser ||
        this.data.createurs.some((c) => c.idUser === this.authService.userBundle.user.idUser) ||
        this.data.correcteurs.some((c) => c.idUser === this.authService.userBundle.user.idUser)) &&
      this.data.participations.some((p) => p.state === ParticipationState.ABANDON)
    );
  }

  utilisePretAEmbarquer(participant: AtelierParticipation) {
    if (!this.numFiche) return;
    if (this.niveau === null) return;
    this.participationService
      .utilisePretAEmbarquer(this.data.atelier.idAtelier, participant.idUser, this.niveau.idAtelierNiveau, this.numFiche)
      .defaultOnError()
      .execute((data) => {
        this.numFiche = null;
        this.niveau = null;
        this.ic2ToastrService.show('Prêt à embarquer utilisé', { classname: 'bg-success text-white' });
        this.atelierComponent.routeChanged();
      });
  }

  showPopup(template, participant: AtelierParticipation) {
    const modal: NgbModalRef = this.ngbModal.open(template, {
      backdrop: 'static',
      centered: true,
      animation: false,
      fullscreen: 'md',
      modalDialogClass: 'animate__animated animate__bounceIn',
      windowClass: 'overflow-hidden',
    });

    modal.result.then(
      (result) => {
        if (result) {
          this.utilisePretAEmbarquer(participant);
        }
      },
      (err) => {}
    );
  }
}
