import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { MsPipe, MultilingualstringConfigService } from '@ic2/ic2-lib';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { JEARight } from 'src/ic2/entities/JEARight';
import { AuthService } from '../tools/auth.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy {
  show: boolean = false;
  title: string = '';
  titleIconClass: string = null;
  _routerSub: Subscription;
  showFrontLanguageChange: boolean;
  currentLang: string;
  boUrl;
  canAddAtelier = false;
  idUser: number;
  avatarExtension;
  isDarkMode: boolean;
  showBackLink: boolean = false;
  JEARight = JEARight;

  constructor(
    public authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    public multilingualstringConfigService: MultilingualstringConfigService,
    private msPipe: MsPipe
  ) {
    this.idUser = this.authService.userBundle.user.idUser;
    this.canAddAtelier = this.authService.has(JEARight.ADMIN, JEARight.CREATEUR);
    this.showBackLink = history.length > 0;
  }

  ngOnInit(): void {
    this._routerSub = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route.snapshot),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .subscribe((route: ActivatedRouteSnapshot) => {
        this.showBackLink = true;
        this.handleRouteData(route);
      });

    let currentRoute = this.route;
    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
    }
    this.handleRouteData(currentRoute.snapshot);

    if (localStorage.getItem('DarkMode') === null) {
      this.isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
      if (this.isDarkMode) localStorage.setItem('DarkMode', 'true');
      if (!this.isDarkMode) localStorage.setItem('DarkMode', 'false');
    }
    if (localStorage.getItem('DarkMode') === 'false') this.isDarkMode = false;
    if (localStorage.getItem('DarkMode') === 'true') this.isDarkMode = true;
    this.applyTheme();
  }

  handleRouteData(route: ActivatedRouteSnapshot) {
    let currentRoute = route;
    let found = false;
    do {
      if (currentRoute.data['title']) {
        this.title = currentRoute.data['title'];
        found = true;
      }
      if (currentRoute.data['icon']) this.titleIconClass = currentRoute.data['icon'];

      if (found) break;
    } while ((currentRoute = route.parent) !== null);

    if (!found) {
      this.title = '';
      this.titleIconClass = null;
    }
  }

  ngOnDestroy() {
    this._routerSub.unsubscribe();
  }

  toggleMenu() {
    this.show = !this.show;
    console.log('toggleMenu');
  }
  hideMenu() {
    this.show = false;
  }

  logout() {
    this.authService.logout();
    localStorage.setItem('forcelogin', 'true');
    this.router.navigateByUrl('/logout');
  }

  toggleDarkMode() {
    this.isDarkMode = !this.isDarkMode;
    if (this.isDarkMode) localStorage.setItem('DarkMode', 'true');
    if (!this.isDarkMode) localStorage.setItem('DarkMode', 'false');
    this.applyTheme();
  }

  private applyTheme() {
    if (localStorage.getItem('DarkMode') === 'true') {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }
}
