import { Component, OnInit } from '@angular/core';
import { FilterForm, FilterOrder, GenericFilter, L, OrderBy } from '@ic2/ic2-lib';
import { AuthService } from 'src/app/tools/auth.service';
import { DateUtils } from 'src/app/tools/DateUtils';
import { AtelierLiteDTO } from 'src/ic2/entities/AtelierLiteDTO';
import { JEARight } from 'src/ic2/entities/JEARight';
import { AtelierService } from 'src/ic2/services/AtelierService';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  data: AtelierLiteDTO[] = [];
  OrderBy: typeof OrderBy = OrderBy;
  HomeComponent: typeof HomeComponent = HomeComponent;
  JEARight: typeof JEARight = JEARight;
  static filtersCache = {};
  filtersDefault = {};

  static page: number = 1;

  form: FilterForm = new FilterForm(this.reload.bind(this), HomeComponent.filtersCache, {}, {});
  nbPerPage: number = 10;
  count = 0;
  loading: boolean = false;
  order: FilterOrder[] = [new FilterOrder('dispoDate')];
  admin: boolean = false;
  matieres: string[] = [];
  types: string[] = [];

  DateUtils = DateUtils;

  constructor(public authService: AuthService, private atelierService: AtelierService) {
    this.admin = authService.has(JEARight.ADMIN);
  }

  ngOnInit(): void {
    this.reload();
  }

  reload() {
    const genericFilter = new GenericFilter();
    genericFilter.filters = this.form.toFilters();
    genericFilter.orderFilters = this.order;
    genericFilter.page = HomeComponent.page;
    genericFilter.nbPerPage = this.nbPerPage;
    this.loading = true;

    this.atelierService.getAteliers(genericFilter).subscribe(
      (data) => {
        this.data = data.data;
        this.count = data.count;
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        L.e(err);
      }
    );
  }

  isFull(atelier: AtelierLiteDTO) {
    return atelier.nbSlotsTaken >= atelier.nbMaxSlots && atelier.nbSlotsTakenPretAEmbarquer >= atelier.nbMaxSlotsPretAEmbarquer;
  }
}
