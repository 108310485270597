// fr.jea.metier.user.services.UserSearchServiceI generated by ic2 0.0.1-SNAPSHOT at Sun Nov 26 22:13:19 UTC 2023
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { JEARight } from '../entities/JEARight';
import { UserLiteDTO } from '../entities/UserLiteDTO';

@Injectable({
  providedIn: 'root',
})
export class UserSearchService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  searchUser(txt: string, rights: JEARight[]): RpcRequestBuilder<UserLiteDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'UserSearchService.searchUser';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(txt, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(rights, 'java.util.List<fr.jea.metier.JEARight>', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

}
