import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthService } from 'src/app/tools/auth.service';
import { DateUtils } from 'src/app/tools/DateUtils';
import { Ic2ToastrService } from 'src/app/tools/ic2-toastr.service';
import { AtelierDTO } from 'src/ic2/entities/AtelierDTO';
import { AtelierNiveau } from 'src/ic2/entities/AtelierNiveau';
import { AtelierParticipation } from 'src/ic2/entities/AtelierParticipation';
import { JEARight } from 'src/ic2/entities/JEARight';
import { ParticipationState } from 'src/ic2/entities/ParticipationState';
import { ParticipationService } from 'src/ic2/services/ParticipationService';

@Component({
  selector: 'app-niveau',
  templateUrl: './niveau.component.html',
  styleUrls: ['./niveau.component.scss'],
})
export class NiveauComponent {
  @Input() data: AtelierDTO;
  @Input() niveau: AtelierNiveau;
  @Input() editMode: boolean;
  @Input() index: number;
  @Output() inscriptionCorrecteur = new EventEmitter<void>();
  @Output() inscriptionParticipant = new EventEmitter<void>();
  @Output() deleteNiveau = new EventEmitter<void>();

  ParticipationState = ParticipationState;
  admin: boolean = false;
  correcteur: boolean = false;

  constructor(private authService: AuthService, private ic2ToastrService: Ic2ToastrService, private participationService: ParticipationService) {
    this.admin = authService.has(JEARight.ADMIN);
    this.correcteur = authService.has(JEARight.ADMIN, JEARight.CREATEUR, JEARight.CORRECTEUR);
  }

  inscriptionPossible() {
    return (
      this.data.participations.filter((p) => p.idUser === this.authService.userBundle.user.idUser).length < this.data.atelier.nbMaxParticipations &&
      DateUtils.isPastOrNow(this.data.atelier.dispoDate) &&
      this.getNbPlacesRestantes() > 0
    );
  }
  inscriptionCorrecteurPossible() {
    return (
      this.data.atelier.idAtelier !== 0 &&
      this.authService.has(JEARight.ADMIN, JEARight.CREATEUR, JEARight.CORRECTEUR) &&
      !this.data.correcteurs.some((p) => p.idUser === this.authService.userBundle.user.idUser && p.idAtelierNiveau === this.niveau.idAtelierNiveau)
    );
  }

  getParticipants() {
    return this.data.atelier.idAtelier !== 0 && this.data.participations.filter((p) => p.idAtelierNiveau === this.niveau.idAtelierNiveau);
  }
  getCorrecteurs() {
    return this.data.atelier.idAtelier !== 0 && this.data.correcteurs.filter((p) => p.idAtelierNiveau === this.niveau.idAtelierNiveau);
  }

  getUser(idUser: number) {
    return this.data.users.find((u) => u.idUser === idUser);
  }

  getNbPlacesRestantes() {
    return this.niveau.nbSlots - this.getParticipants().length;
  }

  copy(str: string) {
    navigator.clipboard.writeText(str);
    this.ic2ToastrService.show('"' + str + '" copié dans le presse-papier', { classname: 'bg-success text-white' });
  }

  canUpdateState() {
    return (
      this.data.atelier.idAtelier !== 0 &&
      this.correcteur &&
      (this.data.atelier.idUserCreator === this.authService.userBundle.user.idUser ||
        this.data.createurs.some((c) => c.idUser === this.authService.userBundle.user.idUser) ||
        this.data.correcteurs.some((c) => c.idUser === this.authService.userBundle.user.idUser && c.idAtelierNiveau === this.niveau.idAtelierNiveau))
    );
  }

  stateChange(participant: AtelierParticipation, state: ParticipationState) {
    this.participationService
      .updateState(this.data.atelier.idAtelier, this.niveau.idAtelierNiveau, participant.idUser, participant.numFiche, state)
      .defaultOnError()
      .execute((data) => {
        participant.state = state;
      });
  }

  canDeleteCorrecteur(idUser: number) {
    return this.authService.has(JEARight.ADMIN, JEARight.CREATEUR) || idUser === this.authService.userBundle.user.idUser;
  }
  deleteCorrecteur(idUser: number) {
    if (!confirm('Êtes-vous sûr de vouloir supprimer ce correcteur ?')) return;
    this.participationService
      .annulerParticiperCorrecteur(this.niveau.idAtelierNiveau, idUser)
      .defaultOnError()
      .execute(() => {
        this.data.correcteurs.splice(
          this.data.correcteurs.findIndex((c) => c.idUser === idUser && c.idAtelierNiveau === this.niveau.idAtelierNiveau),
          1
        );
      });
  }
}
