export class Config {
  production: boolean = false;
  name: string = 'DEV';
  rpcHost: string = 'http://localhost:8080';
  logRpcRequests: boolean = true;
  facebookAppId: string = '319539264145584';
  //WARNING READ

  //Things added here will be undefined if not set in the front config
  //Update model.config.json in jea-server too when adding things here
}
