<div class="container bg-light-30p h-100 pb-3" style="overflow-y: hidden">
  <div class="row mt-3" *ngIf="loading">
    <div class="col text-center"><i class="fa-light fa-spinner spinning fa-4x"></i></div>
  </div>

  <ng-container *ngIf="!loading">
    <div class="row justify-content-center mt-3" *ngIf="!editMode">
      <div class="col col-md-10 col-lg-8">
        <div class="bg-white border p-3">
          <div class="row justify-content-center">
            <div class="col-auto">
              <h2>{{ data.atelier.name }}</h2>
            </div>
          </div>
          <div class="row mt-2 justify-content-between">
            <div class="col-auto">
              <span class="d-none d-md-inline">Type:</span>
              {{ data.atelier.type }}
            </div>
            <div class="col-auto">
              <span class="d-none d-md-inline">Matière:</span>
              {{ data.atelier.matiere }}
            </div>
          </div>
          <div class="row mt-1 justify-content-between">
            <div class="col-auto">
              <i class="fa-light fa-clock me-2"></i>
              <span class="d-none d-md-inline">Date de lancement:</span>
              {{ data.atelier.dispoDate | date : 'dd/MM/yyyy HH:mm' }}
            </div>
            <div class="col-auto">
              <i class="fa-light fa-alarm-clock me-2"></i>
              <span class="d-none d-md-inline">Date de fin:</span>
              {{ data.atelier.endDate | date : 'dd/MM/yyyy' }}
            </div>
          </div>
          <div class="row mt-3">
            <div class="col">
              Créateurs:
              <ul>
                <li *ngFor="let createur of data.createurs">
                  <i class="fa-light fa-user-circle"></i>
                  {{ getUser(createur.idUser).firstName + ' ' + getUser(createur.idUser).lastName }}
                  <span class="my-fs-1 pointer" (click)="copy(getUser(createur.idUser).email)">({{ getUser(createur.idUser).email }})</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="row justify-content-between align-items-center mt-3">
            <div class="col my-fs-4" *ngIf="getNbPlacesRestantes() > 0">
              {{ getNbPlacesRestantes() }} place(s) restante(s) sur {{ getNbPlacesTotal() }}
              <span class="my-fs-1" *ngIf="data.atelier.nbMaxParticipations > 1">({{ data.atelier.nbMaxParticipations }} participations max par personne)</span>
            </div>
            <div class="col-auto my-fs-4" *ngIf="getNbPlacesRestantes() <= 0">
              {{ getNbPlacesTotal() }} place(s)
              <div class="text-danger" *ngIf="getNbPlacesTotal() > 0">COMPLET</div>
            </div>
            <div class="col-auto text-end" *ngIf="createur"><button class="btn btn-primary" (click)="editMode = true">Editer</button></div>
          </div>
          <div class="row mt-3" *ngIf="createur && getNbPlacesRestantes() <= 0 && getNbPlacesTotal() > 0">
            <div class="col-auto text-end"><button class="btn btn-secondary" (click)="trameEnvoyee()">Trame envoyée</button></div>
          </div>
          <div class="row mt-3" *ngIf="createur">
            <div class="col-auto text-end">
              <button class="btn btn-sm btn-secondary" (click)="copyAllAdresses()">Copier toutes les adresses mails des participants</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <form #ngForm="ngForm" (submit)="save()">
      <div class="row justify-content-center mt-3" *ngIf="editMode">
        <div class="col col-md-10 col-lg-8">
          <div class="bg-white border p-3">
            <div class="row">
              <div class="col">Nom de l'atelier</div>
            </div>
            <div class="row mt-2">
              <div class="col"><input class="form-control" name="name" type="text" [(ngModel)]="data.atelier.name" required /></div>
            </div>
            <div class="row mt-3">
              <div class="col">Description de l'atelier</div>
            </div>
            <div class="row mt-2">
              <div class="col"><textarea class="form-control" name="description" rows="3" [(ngModel)]="data.atelier.description"></textarea></div>
            </div>
            <div class="row mt-3">
              <div class="col">Type</div>
            </div>
            <div class="row mt-2">
              <div class="col">
                <ic2-textassist name="type" [(ngModel)]="data.atelier.type" [values]="types" [allowTypedTextToBeTheModel]="true" required></ic2-textassist>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col">Matière</div>
            </div>
            <div class="row mt-2">
              <div class="col">
                <ic2-textassist
                  name="matiere"
                  [(ngModel)]="data.atelier.matiere"
                  [values]="matieres"
                  [allowTypedTextToBeTheModel]="true"
                  required
                ></ic2-textassist>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col">Discipline</div>
            </div>
            <div class="row mt-2">
              <div class="col">
                <ic2-textassist
                  name="discipline"
                  [(ngModel)]="data.atelier.discipline"
                  [values]="disciplines"
                  [allowTypedTextToBeTheModel]="true"
                  required
                ></ic2-textassist>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col">Public</div>
            </div>
            <div class="row mt-2">
              <div class="col">
                Maternelle
                <input class="form-check-input" name="maternelle" type="checkbox" [(ngModel)]="data.atelier.maternelle" />
              </div>
              <div class="col">
                Élémentaire
                <input class="form-check-input" name="elementaire" type="checkbox" [(ngModel)]="data.atelier.elementaire" />
              </div>
            </div>
            <div class="row mt-3">
              <div class="col">Date de lancement</div>
            </div>
            <div class="row mt-2">
              <div class="col"><hiji-datetime-picker name="dispoDate" [(ngModel)]="data.atelier.dispoDate" required></hiji-datetime-picker></div>
            </div>
            <div class="row mt-3">
              <div class="col">Date de fin</div>
            </div>
            <div class="row mt-2">
              <div class="col"><ic2-input-date name="endDate" [(ngModel)]="data.atelier.endDate" required /></div>
            </div>
            <div class="row mt-3">
              <div class="col">Nombre de places dans le prêt à embarquer</div>
            </div>
            <div class="row mt-2">
              <div class="col">
                <input class="form-control" name="nbSlotPretAEmbarquer" type="number" [(ngModel)]="data.atelier.nbSlotPretAEmbarquer" required />
              </div>
            </div>
            <div class="row mt-3">
              <div class="col">Durée estimée</div>
            </div>
            <div class="row mt-2">
              <div class="col">
                <div class="input-group">
                  <input class="form-control" name="estimatedDurationMin" type="number" [(ngModel)]="data.atelier.estimatedDurationMin" required />
                  <div class="input-group-append">
                    <span class="input-group-text">minutes</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col">Nombre de participation maximum</div>
            </div>
            <div class="row mt-2">
              <div class="col">
                <input class="form-control" name="nbMaxParticipations" type="number" [(ngModel)]="data.atelier.nbMaxParticipations" required />
              </div>
            </div>
            <div class="row mt-3">
              <div class="col">Créateurs</div>
            </div>
            <div class="row mt-2">
              <div class="col">
                <hiji-user-textassist
                  placeholder="Rechercher un créateur"
                  [rights]="[]"
                  [predicat]="predicat.bind(this)"
                  [clearAfterAdd]="true"
                  (objectChange)="addCreateur($event)"
                ></hiji-user-textassist>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-auto my-2" *ngFor="let createur of data.createurs">
                <div class="bg-light p-2 rounded-2">
                  <i class="fa-light fa-user-circle me-2"></i>
                  {{ getUser(createur.idUser).firstName + ' ' + getUser(createur.idUser).lastName }}
                  <i class="fa-light fa-trash text-danger-hover pointer ms-2" (click)="removeCreateur(createur)"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <app-niveau
        [editMode]="editMode"
        [niveau]="niveau"
        [data]="data"
        (inscriptionCorrecteur)="inscriptionCorrecteur(niveau.idAtelierNiveau)"
        (inscriptionParticipant)="inscriptionParticipant(niveau.idAtelierNiveau)"
        (deleteNiveau)="deleteNiveau(niveau)"
        *ngFor="let niveau of getNonDeletedNiveaux(); let index = index; trackBy: trackByIndex"
      ></app-niveau>

      <div class="row justify-content-center mt-3" *ngIf="editMode">
        <div class="col col-md-10 col-lg-8 pointer" (click)="addNiveau()">
          <div class="bg-white border p-3">
            <div class="row">
              <div class="col">
                <i class="fa-light fa-plus me-2"></i>
                Ajouter un niveau
              </div>
            </div>
          </div>
        </div>
      </div>

      <app-pret-a-embarquer
        [data]="data"
        [editMode]="editMode"
        (inscriptionParticipant)="inscriptionParticipant(0)"
        *ngIf="data.atelier.nbSlotPretAEmbarquer > 0"
      ></app-pret-a-embarquer>

      <div class="row justify-content-end mt-4" *ngIf="ngForm.submitted && !form.valid">
        <div class="col-auto">
          <ul class="errors">
            <li *ngIf="form.hasError('required', 'name')">Un nom est requis</li>
            <li *ngIf="form.hasError('required', 'type')">Un type est requis</li>
            <li *ngIf="form.hasError('required', 'matiere')">Une matière est requis</li>
            <li *ngIf="form.hasError('required', 'discipline')">Une discipline est requise</li>
            <li *ngIf="form.hasError('required', 'dispoDate')">Une date de lancement est requise</li>
            <li *ngIf="form.hasError('required', 'endDate')">Une date de fin est requise</li>
          </ul>
        </div>
      </div>

      <div class="row mt-3 justify-content-end" *ngIf="editMode">
        <div class="col-auto mt-2 mt-md-0">
          <button class="btn btn-medium" type="button" (click)="cancel()">
            <i class="fa-light fa-undo-alt me-0 me-md-2"></i>
            <span class="d-none d-md-inline">{{ 'Annuler' }}</span>
          </button>
        </div>
        <div class="col-auto mt-2 mt-md-0">
          <button class="btn btn-primary" type="submit" [disabled]="saveLoading">
            <i class="fa-light fa-save me-0 me-md-2"></i>
            <span class="d-none d-md-inline">{{ 'Valider' }}</span>
            <i class="fa-light fa-spinner spinning ms-2" *ngIf="saveLoading"></i>
          </button>
        </div>
      </div>
    </form>
  </ng-container>
</div>
