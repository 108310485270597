import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { L } from '@ic2/ic2-lib';
import { AuthService } from 'src/app/tools/auth.service';
import { ResetPwdService } from 'src/ic2/services/ResetPwdService';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  form: FormGroup = this.fb.group({
    email: [null, [Validators.required, Validators.email]],
  });
  loading: boolean = false;
  error: boolean = false;
  email: string = '';
  static emailFromLogin: string = null;

  constructor(private fb: FormBuilder, private resetPwdService: ResetPwdService, private router: Router, private authService: AuthService) {}

  ngOnInit(): void {
    this.authService.logout();
    if (ResetPasswordComponent.emailFromLogin != null) {
      this.email = ResetPasswordComponent.emailFromLogin;
      this.form.patchValue({ email: this.email });
    }
  }

  submit() {
    this.error = false;
    if (!this.form.valid) {
      return;
    }
    this.loading = true;
    this.resetPwdService.forgotPassword(this.form.value.email).subscribe(
      (data) => {
        this.loading = false;
        this.router.navigate(['/reset-password/confirm']);
      },
      (err) => {
        this.loading = false;
        if (err.isBusinessError()) {
          this.error = true;
        } else L.e(err);
      }
    );
  }
}
