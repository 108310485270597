import { finalize } from 'rxjs';
import { AuthService } from './tools/auth.service';
import { Config } from './tools/Config';

export function facebookInitializer(config: Config, authService: AuthService) {
  return () =>
    new Promise((resolve) => {
      // wait for facebook sdk to initialize before starting the angular app
      window['fbAsyncInit'] = function () {
        window['FB'].init({
          appId: config.facebookAppId,
          cookie: true,
          xfbml: true,
          version: 'v8.0',
        });

        // auto login to the api if already logged in with facebook
        window['FB'].getLoginStatus(({ authResponse }) => {
          if (authResponse) {
            authService.isLoggedIn().subscribe((loggedIn) => {
              if (!loggedIn)
                authService
                  .loginWithFBToken(authResponse.accessToken)
                  .pipe(finalize(() => resolve(null)))
                  .subscribe();
              else resolve(null);
            });
          } else {
            resolve(null);
          }
        });
      };

      // load facebook sdk script
      (function (d, s, id) {
        var js: any;
        var fjs: any = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
    });
}
