import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { L, TextassistComponent } from '@ic2/ic2-lib';
import { JEARight } from 'src/ic2/entities/JEARight';
import { UserLiteDTO } from 'src/ic2/entities/UserLiteDTO';
import { UserSearchService } from 'src/ic2/services/UserSearchService';

@Component({
  selector: 'hiji-user-textassist',
  templateUrl: './user-textassist.component.html',
  styleUrls: ['./user-textassist.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserTextassistComponent),
      multi: true,
    },
  ],
})
export class UserTextassistComponent implements OnChanges, ControlValueAccessor {
  @Input()
  initialText: string = null;
  @Input()
  placeholder: string = null;
  @Input()
  clearAfterAdd: boolean = false;
  @Input()
  rights: JEARight[] = [];
  @Input()
  predicat = (user: UserLiteDTO): boolean => {
    return true;
  };
  @Output()
  objectChange: EventEmitter<UserLiteDTO> = new EventEmitter<UserLiteDTO>();

  clearing: boolean = false;

  @ViewChild('ta') ta: TextassistComponent<UserLiteDTO>;

  idUser: number;
  user: UserLiteDTO;
  users: UserLiteDTO[] = [];

  userPresenter = (e: UserLiteDTO) => e.firstName + ' ' + e.lastName;

  constructor(private userSearchService: UserSearchService, private cdRef: ChangeDetectorRef) {}
  onChange: any = () => {};
  onTouch: any = () => {};

  registerOnChange(fn: number) {
    this.onChange = fn;
  }
  registerOnTouched(fn: number) {
    this.onTouch = fn;
  }

  writeValue(idUser: number) {
    this.idUser = idUser;
  }

  ngOnChanges(changes: SimpleChanges) {}

  add(user) {
    this.setValue(user);
  }

  setValue(user: UserLiteDTO) {
    if (this.clearing) return;
    if (user !== null && user !== undefined) {
      this.idUser = user.idUser;
    } else {
      this.idUser = null;
    }
    this.change();
    if (this.clearAfterAdd) {
      this.clearing = true;
      this.ta.clear();
      this.clearing = false;
    }
  }

  change() {
    if (this.user === null && this.clearAfterAdd) return;
    this.objectChange.emit(this.user);
    this.onChange(this.idUser);
    this.onTouch(this.idUser);
  }

  userTextChanged(text: string): void {
    if (text.length === 0) return;
    this.userSearchService.searchUser(text, this.rights).subscribe(
      (data) => {
        this.users = data.filter(this.predicat);
      },
      (err) => {
        L.e(err);
      }
    );
  }
}
