<div class="d-flex align-items-center" style="min-height: 100vh !important">
  <div class="container bg-white rounded shadow-2 py-3 m-3 mx-sm-auto" style="z-index: 10">
    <div class="row">
      <div class="col-auto text-end d-none d-md-flex align-items-center">
        <img class="w-100" src="assets/common/image_forget_password.jpg" alt="reset password image" />
      </div>
      <div class="col">
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-auto">
              <img style="width: 75px" src="assets/logo.png" />
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12 text-center justify-content-center">
              <div class="col">
                <h4>
                  {{ 'Vous avez oublié votre mot de passe ?' }}
                  <br />
                  {{ 'Ça arrive même aux meilleurs' }}
                </h4>
                <p class="mt-2">
                  {{ "Saisissez l'adresse email associée à votre compte" }}
                  <br />
                  {{ 'Nous vous enverrons un lien pour réinitialiser votre mot de passe' }}
                </p>
              </div>
            </div>
          </div>
          <form #f="ngForm" [formGroup]="form" (ngSubmit)="submit()">
            <div class="row mt-4">
              <div class="col"><input class="form-control" type="email" placeholder="E-mail" formControlName="email" /></div>
            </div>
            <div class="row mt-3" *ngIf="error || (f.submitted && !form.valid)">
              <div class="col">
                <div class="errors">
                  <ul class="text-start">
                    <li *ngIf="form.hasError('required', 'email')">{{ "L'E-mail est requis" }}</li>
                    <li *ngIf="form.hasError('email', 'email')">{{ "L'E-mail est invalide" }}</li>
                    <li *ngIf="error">
                      {{ "Oops, cette adresse n'est pas répertoriée dans nos bases. Essayez une autre adresse ou" }}
                      <a routerLink="/signup">
                        <u>{{ 'créez tout de suite votre compte' }}</u>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col text-end">
                <button class="btn btn-primary text-uppercase" type="submit" [disabled]="loading">
                  {{ 'Valider' }}
                  <i class="fa-light fa-spinner ms-2 spinning" *ngIf="loading"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
