// fr.jea.metier.jea.services.ParticipationServiceI generated by ic2 0.0.1-SNAPSHOT at Sun Nov 26 22:13:20 UTC 2023
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { ParticipationState } from '../entities/ParticipationState';
import { AtelierParticipation } from '../entities/AtelierParticipation';
import { AtelierCorrecteur } from '../entities/AtelierCorrecteur';

@Injectable({
  providedIn: 'root',
})
export class ParticipationService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  participerCorrecteur(idAtelierNiveau: number): RpcRequestBuilder<AtelierCorrecteur> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ParticipationService.participerCorrecteur';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idAtelierNiveau, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.jea.metier.jea.entities.AtelierCorrecteur');
  }

  annulerParticiperCorrecteur(idAtelierNiveau: number, idUser: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ParticipationService.annulerParticiperCorrecteur';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idAtelierNiveau, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  trameEnvoyee(idAtelier: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ParticipationService.trameEnvoyee';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idAtelier, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateState(idAtelier: number, idAtelierNiveau: number, idUser: number, numFiche: number, state: ParticipationState): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ParticipationService.updateState';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idAtelier, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idAtelierNiveau, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(numFiche, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(state, 'fr.jea.metier.jea.entities.ParticipationState', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  utilisePretAEmbarquer(idAtelier: number, idUser: number, idAtelierNiveau: number, numFiche: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ParticipationService.utilisePretAEmbarquer';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idAtelier, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idAtelierNiveau, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(numFiche, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  participer(idAtelier: number, idAtelierNiveau: number): RpcRequestBuilder<AtelierParticipation> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ParticipationService.participer';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idAtelier, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idAtelierNiveau, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.jea.metier.jea.entities.AtelierParticipation');
  }

}
