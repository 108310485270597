<div class="row justify-content-center mt-3">
  <div class="col col-md-10 col-lg-8">
    <div class="bg-white border p-3">
      <div class="row align-items-center mt-2">
        <div class="col my-fs-5">
          <i class="fa-light fa-ship me-2"></i>
          Prêt à embarquer
        </div>

        <div class="col-auto my-fs-4" *ngIf="getNbPlacesRestantesPretAEmbarquer() > 0">
          {{ getNbPlacesRestantesPretAEmbarquer() }} places restantes sur {{ data.atelier.nbSlotPretAEmbarquer }}
        </div>
        <div class="col-auto my-fs-4" *ngIf="getNbPlacesRestantesPretAEmbarquer() <= 0">
          {{ data.atelier.nbSlotPretAEmbarquer }} places
          <div class="text-danger">COMPLET</div>
        </div>
      </div>
      <div class="row">
        <div class="col"><hr /></div>
      </div>
      <div class="row">
        <div class="col"><b>Participants</b></div>
      </div>
      <div class="row" *ngIf="getParticipantsPretAEmbarquer().length === 0">
        <div class="col">Aucun participant pour le moment</div>
      </div>
      <div class="row g-1" *ngFor="let participant of getParticipantsPretAEmbarquer()">
        <div class="col">
          <i class="fa-light fa-user-circle me-2"></i>
          {{ getUser(participant.idUser).firstName + ' ' + getUser(participant.idUser).lastName }}
          <span class="my-fs-1 pointer" (click)="copy(getUser(participant.idUser).email)" *ngIf="correcteur">({{ getUser(participant.idUser).email }})</span>
        </div>
        <ng-container *ngIf="canUpdateState()">
          <div class="col-auto" *ngIf="participant.idAtelierNiveau === null">
            <button class="btn btn-secondary h-100" type="button" (click)="showPopup(popup, participant)">Utiliser</button>
          </div>
          <div class="col-auto" *ngIf="participant.idAtelierNiveau !== null">Déjà utilisé</div>
        </ng-container>
      </div>
      <div class="row justify-content-end mt-3" *ngIf="!editMode">
        <div class="col-auto mt-2" *ngIf="inscriptionPretAEmbarquerPossible()">
          <button class="btn btn-secondary" type="button" (click)="inscriptionParticipant.emit()">S'inscrire</button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #popup let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Utilisation d'un prêt à embarquer</h4>
  </div>
  <div class="modal-body text-center">
    <div class="row">
      <div class="col">
        <ic2-select
          [values]="getNiveaux()"
          [allowNull]="false"
          [presenter]="presentNiveau"
          placeholder="Niveau"
          [(ngModel)]="niveau"
          [ngModelOptions]="{ standalone: true }"
        ></ic2-select>
      </div>
      <div class="col">
        <input class="form-control h-100" type="number" placeholder="N° fiche" [(ngModel)]="numFiche" [ngModelOptions]="{ standalone: true }" />
      </div>
    </div>
  </div>
  <div class="modal-footer justify-content-between">
    <button class="btn btn-outline-medium" type="button" (click)="modal.dismiss('cancel')">Annuler</button>
    <button class="btn btn-secondary" type="button" (click)="modal.close()" [disabled]="!numFiche || !niveau">Confirmer</button>
  </div>
</ng-template>
