import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { L } from '@ic2/ic2-lib';
import { filter, interval, Subscription } from 'rxjs';
import { AuthService } from 'src/app/tools/auth.service';
import { DateUtils } from 'src/app/tools/DateUtils';
import { Ic2ToastrService } from 'src/app/tools/ic2-toastr.service';
import { Atelier } from 'src/ic2/entities/Atelier';
import { AtelierCreateur } from 'src/ic2/entities/AtelierCreateur';
import { AtelierDTO } from 'src/ic2/entities/AtelierDTO';
import { AtelierNiveau } from 'src/ic2/entities/AtelierNiveau';
import { JEARight } from 'src/ic2/entities/JEARight';
import { ParticipationState } from 'src/ic2/entities/ParticipationState';
import { UserLiteDTO } from 'src/ic2/entities/UserLiteDTO';
import { AtelierService } from 'src/ic2/services/AtelierService';
import { ParticipationService } from 'src/ic2/services/ParticipationService';

@Component({
  selector: 'app-atelier',
  templateUrl: './atelier.component.html',
  styleUrls: ['./atelier.component.scss'],
})
export class AtelierComponent implements OnInit, OnDestroy {
  data: AtelierDTO = null;

  private sub: Subscription;
  private subTimer: Subscription;

  _ngForm: NgForm = null;
  get ngForm() {
    return this._ngForm;
  }
  @ViewChild('ngForm', { static: false }) set ngForm(val: NgForm) {
    this._ngForm = val;
    if (this._ngForm !== undefined) {
      this.form = val.form;
    }
  }
  form: FormGroup = null;

  loading: boolean = true;
  saveLoading: boolean = false;
  matieres: string[] = [];
  types: string[] = [];
  disciplines: string[] = [];

  admin: boolean = false;
  createur: boolean = false;
  correcteur: boolean = false;
  editMode: boolean = false;
  idAtelier: string = null;
  JEARight = JEARight;
  predicat = (user: UserLiteDTO) => !this.data.createurs.some((createur) => createur.idUser === user.idUser);

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private atelierService: AtelierService,
    private participationService: ParticipationService,
    private authService: AuthService,
    private ic2ToastrService: Ic2ToastrService
  ) {
    this.admin = authService.has(JEARight.ADMIN);
    this.createur = authService.has(JEARight.ADMIN, JEARight.CREATEUR);
    this.correcteur = authService.has(JEARight.ADMIN, JEARight.CREATEUR, JEARight.CORRECTEUR);
  }

  trackByIndex(index: number, item: AtelierNiveau) {
    return index;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    if (this.subTimer) this.subTimer.unsubscribe();
  }

  ngOnInit(): void {
    this.sub = this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((_) => {
      this.routeChanged();
    });
    this.routeChanged();
  }

  routeChanged() {
    this.loading = true;
    this.idAtelier = this.route.snapshot.paramMap.get('idAtelier');

    this.atelierService
      .getMatieres()
      .defaultOnError()
      .execute((data) => {
        this.matieres = data;
      });
    this.atelierService
      .getTypes()
      .defaultOnError()
      .execute((data) => {
        this.types = data;
      });
    this.atelierService
      .getDisciplines()
      .defaultOnError()
      .execute((data) => {
        this.disciplines = data;
      });

    if (this.idAtelier === 'add') {
      this.data = new AtelierDTO();
      this.data.atelier = new Atelier();
      this.data.atelier.nbMaxParticipations = 1;
      let ac = new AtelierCreateur();
      ac.idUser = this.authService.userBundle.user.idUser;
      this.data.createurs.push(ac);
      let u = new UserLiteDTO();
      u.idUser = this.authService.userBundle.user.idUser;
      u.firstName = this.authService.userBundle.user.firstName;
      u.lastName = this.authService.userBundle.user.lastName;
      u.email = this.authService.userBundle.user.email;
      this.data.users.push(u);
      this.editMode = true;
      this.loading = false;
    } else {
      this.atelierService
        .getAtelier(parseInt(this.idAtelier, 10))
        .defaultOnError()
        .execute((data) => {
          this.data = data;
          if (DateUtils.isFuture(this.data.atelier.dispoDate))
            this.subTimer = interval(1000).subscribe((_) => {
              if (DateUtils.isPastOrNow(this.data.atelier.dispoDate)) {
                this.ic2ToastrService.show('Les inscriptions sont ouvertes !', { classname: 'bg-warning text-white' });
                this.subTimer.unsubscribe();
                this.data.niveaux = [...this.data.niveaux]; //to force refresh
              }
            });
          this.loading = false;
        });
    }
  }

  cancel() {
    if (this.idAtelier === 'add') this.router.navigate(['/']);
    else {
      this.editMode = false;
      this.routeChanged();
    }
  }

  save() {
    if (!this.ngForm.form.valid) {
      console.log(this.ngForm);
      return;
    }
    this.saveLoading = true;
    const wasAdding = this.data.atelier.idAtelier === 0;
    this.atelierService
      .saveAtelier(this.data)
      .defaultOnError()
      .execute((data) => {
        this.data = data;
        if (wasAdding) this.router.navigate(['/atelier', this.data.atelier.idAtelier]);
        this.saveLoading = false;
        this.editMode = false;
      });
  }

  getNbPlacesRestantes() {
    return this.getNbPlacesTotal() - this.data.participations.filter((ap) => ap.idAtelierNiveau !== null).length;
  }

  getNbPlacesTotal() {
    return this.data.niveaux.filter((n) => !n.del).reduce((acc, n) => acc + n.nbSlots, 0);
  }

  getNonDeletedNiveaux() {
    return this.data.niveaux.filter((n) => !n.del).sort((a, b) => a.order - b.order);
  }

  getUser(idUser: number) {
    return this.data.users.find((u) => u.idUser === idUser);
  }

  copy(str: string) {
    navigator.clipboard.writeText(str);
    this.ic2ToastrService.show('"' + str + '" copié dans le presse-papier', { classname: 'bg-success text-white' });
  }

  addNiveau() {
    const niveau = new AtelierNiveau();
    niveau.nbCorrecteurSlots = 1;
    niveau.nbSlots = 10;
    niveau.order = this.data.niveaux.length + 1;
    this.data.niveaux.push(niveau);
  }

  addCreateur(user: UserLiteDTO) {
    if (user === null || user === undefined) return;
    if (this.data.createurs.some((createur) => createur.idUser === user.idUser)) return;
    this.data.users.push(user);
    const ac = new AtelierCreateur();
    ac.idUser = user.idUser;
    ac.idAtelier = this.data.atelier.idAtelier;
    this.data.createurs.push(ac);
  }

  removeCreateur(createur: AtelierCreateur) {
    this.data.createurs.splice(this.data.createurs.indexOf(createur), 1);
  }

  inscriptionParticipant(idAtelierNiveau: number) {
    this.participationService
      .participer(this.data.atelier.idAtelier, idAtelierNiveau)
      .onError((err) => {
        if (err.isBusinessError() && err.data && err.data['code'] === 'no_more_slots') {
          this.ic2ToastrService.show('Plus de place disponible', { classname: 'bg-danger text-white' });
        } else if (err.isBusinessError() && err.data && err.data['code'] === 'max_participations_reached') {
          this.ic2ToastrService.show('Vous avez atteint le nombre maximum de participations possibles (' + this.data.atelier.nbMaxParticipations + ')', {
            classname: 'bg-danger text-white',
          });
        } else if (err.isBusinessError() && err.data && err.data['code'] === 'not_yet_open') {
          this.ic2ToastrService.show('Les inscriptions ne sont pas encore ouvertes', {
            classname: 'bg-danger text-white',
          });
        } else L.e(err);
      })
      .execute((data) => {
        this.data.participations.push(data);
        if (!this.data.users.some((u) => u.idUser === this.authService.userBundle.user.idUser)) {
          let u = new UserLiteDTO();
          u.idUser = this.authService.userBundle.user.idUser;
          u.firstName = this.authService.userBundle.user.firstName;
          u.lastName = this.authService.userBundle.user.lastName;
          u.email = this.authService.userBundle.user.email;
          this.data.users.push(u);
        }
        this.ic2ToastrService.show('Vous êtes bien inscrit !', {
          classname: 'bg-success text-white',
        });
      });
  }

  inscriptionCorrecteur(idAtelierNiveau: number) {
    this.participationService
      .participerCorrecteur(idAtelierNiveau)
      .onError((err) => {
        if (err.isBusinessError() && err.data && err.data['code'] === 'no_more_slots') {
          this.ic2ToastrService.show('Plus de place disponible', { classname: 'bg-danger text-white' });
        } else if (err.isBusinessError() && err.data && err.data['code'] === 'max_participations_reached') {
          this.ic2ToastrService.show('Vous avez atteint le nombre maximum de participations possibles (' + this.data.atelier.nbMaxParticipations + ')', {
            classname: 'bg-danger text-white',
          });
        } else L.e(err);
      })
      .execute((data) => {
        this.data.correcteurs.push(data);
        if (!this.data.users.some((u) => u.idUser === this.authService.userBundle.user.idUser)) {
          let u = new UserLiteDTO();
          u.idUser = this.authService.userBundle.user.idUser;
          u.firstName = this.authService.userBundle.user.firstName;
          u.lastName = this.authService.userBundle.user.lastName;
          u.email = this.authService.userBundle.user.email;
          this.data.users.push(u);
        }
        this.ic2ToastrService.show('Vous êtes bien inscrit !', {
          classname: 'bg-success text-white',
        });
      });
  }

  trameEnvoyee() {
    this.participationService
      .trameEnvoyee(this.data.atelier.idAtelier)
      .defaultOnError()
      .execute((data) => {
        this.ic2ToastrService.show('Trame envoyée', { classname: 'bg-success text-white' });
        this.data.participations.filter((pa) => pa.idAtelierNiveau !== null).forEach((pa) => (pa.state = ParticipationState.TRAME_ENVOYE));
      });
  }

  copyAllAdresses() {
    const unique = (value, index, self) => {
      return self.indexOf(value) === index;
    };
    navigator.clipboard.writeText(
      this.data.participations
        .filter((pa) => pa.idAtelierNiveau !== null)
        .map((pa) => this.data.users.find((u) => u.idUser === pa.idUser).email)
        .filter(unique)
        .join(';')
    );
    this.ic2ToastrService.show('Adresses copiées dans le presse-papier', { classname: 'bg-success text-white' });
  }

  deleteNiveau(niveau: AtelierNiveau) {
    if (!confirm('Êtes-vous sûr de vouloir supprimer ce niveau ?')) return;
    niveau.del = true;
  }
}
