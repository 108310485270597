import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { L, RpcError } from '@ic2/ic2-lib';
import { AuthService } from 'src/app/tools/auth.service';
import { ResetPasswordComponent } from '../reset-password/reset-password/reset-password.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  form: FormGroup = this.fb.group({
    email: [null, [Validators.required, Validators.email]],
    password: [null, [Validators.required, Validators.minLength(6)]],
  });
  error: string = null;
  loading: boolean = false;

  constructor(private fb: FormBuilder, private authService: AuthService, private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.authService.isLoggedIn().subscribe((data) => {
      if (data) this.router.navigate(['/']);
      else this.authService.logout();
    });
    this.form.valueChanges.subscribe((formValues) => {
      ResetPasswordComponent.emailFromLogin = formValues.email;
    });
  }

  submit() {
    if (!this.form.valid) {
      return;
    }
    if (this.loading) return;
    this.loading = true;
    this.authService.login(this.form.value.email, this.form.value.password).subscribe(
      (data) => {
        this.loading = false;
        if (data.redirectUrl === null) data.redirectUrl = '/';
      },
      (err: RpcError) => {
        this.loading = false;
        if (err.isBusinessError()) {
          this.error = 'Echec de la connexion';
        } else if (err.code === -33000) {
          this.error = 'Maintenance en cours';
        } else {
          this.error = 'Une erreur est survenue lors de la connexion';
          L.e(err);
        }
      }
    );
  }
}
