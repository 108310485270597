// fr.jea.metier.jea.services.AtelierServiceI generated by ic2 0.0.1-SNAPSHOT at Sun Nov 26 22:13:19 UTC 2023
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder, ListWithCount, GenericFilter } from '@ic2/ic2-lib';
import { AtelierDTO } from '../entities/AtelierDTO';
import { AtelierLiteDTO } from '../entities/AtelierLiteDTO';

@Injectable({
  providedIn: 'root',
})
export class AtelierService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getTypes(): RpcRequestBuilder<string[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'AtelierService.getTypes';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  saveAtelier(dto: AtelierDTO): RpcRequestBuilder<AtelierDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'AtelierService.saveAtelier';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(dto, 'fr.jea.metier.jea.dto.AtelierDTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.jea.metier.jea.dto.AtelierDTO');
  }

  getAtelier(idAtelier: number): RpcRequestBuilder<AtelierDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'AtelierService.getAtelier';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idAtelier, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.jea.metier.jea.dto.AtelierDTO');
  }

  getMatieres(): RpcRequestBuilder<string[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'AtelierService.getMatieres';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getDisciplines(): RpcRequestBuilder<string[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'AtelierService.getDisciplines';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getAteliers(filter: GenericFilter): RpcRequestBuilder<ListWithCount<AtelierLiteDTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'AtelierService.getAteliers';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'com.ic2.utils.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

}
