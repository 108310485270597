<div class="d-flex align-items-center" style="min-height: 100vh !important">
  <div class="bg-white rounded shadow-2 p-3 mx-auto" style="z-index: 10">
    <div class="row">
      <div class="col-12 d-flex align-items-center">
        <div class="container-fluid">
          <div class="row text-center justify-content-center">
            <div class="col-auto">
              <div>
                <img style="width: 50px" src="assets/logo.png" />

                <h3 class="mt-2">{{ 'Vous voilà déconnecté(e)' }}...</h3>
              </div>
              <div class="mt-2">mais vous avez le doit de changer d'avis 😊</div>
            </div>
          </div>
          <div class="row mt-3 text-center justify-content-center-5 mt-2">
            <div class="col">
              <a class="btn btn-primary text-uppercase" routerLink="/">{{ 'ME CONNECTER' }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
