<div class="modal-header">
  <h5 class="modal-title">Traitement des données</h5>
</div>
<div class="modal-body">
  <div class="container-fluid">
    <div class="row py-4 justify-content-center">
      <div class="col-auto">
        <div>
          *Les informations recueillies par le biais de ce formulaire sont enregistrées, conservées et archivées et nous permettent de vous recontacter.
        </div>
        <div>
          <br />
        </div>
        <div>La base légale du traitement est votre consentement.</div>
        <div>
          <br />
        </div>
        <div>Les données sont conservées jusqu'à votre désinscription et sont transmises seulement aux administrateurs de Jouer et apprendre.</div>
        <div>
          <br />
        </div>
        <div>
          Vous disposez d'un droit d'accès, de rectification, d'opposition, de limitation au traitement et d'effacement. Pour plus d'informations concernant le
          traitement de vos données personnelles par jouer et apprendre, vous pouvez consulter notre
          <a href="/policy">Politique de protection des données</a>
          ou contacter notre DPO à l'adresse suivante :
          <a href="mailto:dpo@rockpalm.fr">dpo@rockpalm.fr</a>
          .&nbsp;
        </div>
        <div>
          <br />
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-dark" type="button" data-dismiss="modal" (click)="modal.dismiss()">Fermer</button>
</div>
