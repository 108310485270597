// ENTITY fr.jea.metier.jea.entities.AtelierCorrecteur generated by ic2 0.0.1-SNAPSHOT at Sun Nov 26 22:13:25 UTC 2023

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class AtelierCorrecteur {

  idAtelierNiveau: number | null = 0;
  idUser: number | null = 0;
  inscriptionDate: Date | null = null;

  constructor(init?:Partial<AtelierCorrecteur>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.jea.metier.jea.entities.AtelierCorrecteur'] = AtelierCorrecteur;

reverseMapping[AtelierCorrecteur.name] = 'fr.jea.metier.jea.entities.AtelierCorrecteur';

fields['fr.jea.metier.jea.entities.AtelierCorrecteur']  = {
    idAtelierNiveau: 'java.lang.Integer',
    idUser: 'java.lang.Integer',
    inscriptionDate: 'java.time.Instant'
};
