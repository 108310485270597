import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { L, UserEditValidators } from '@ic2/ic2-lib';
import { ResetPwdService } from 'src/ic2/services/ResetPwdService';

@Component({
  selector: 'app-reset-password-form',
  templateUrl: './reset-password-form.component.html',
  styleUrls: ['./reset-password-form.component.scss'],
})
export class ResetPasswordFormComponent implements OnInit {
  form: FormGroup = this.fb.group(
    {
      password: [null, [Validators.required, Validators.minLength(6)]],
      passwordConfirm: [null, [Validators.required, Validators.minLength(6)]],
    },
    {
      validator: UserEditValidators.checkPasswords('password', 'passwordConfirm'),
    }
  );
  loading: boolean = false;
  error: boolean = false;
  token: string = null;

  constructor(private fb: FormBuilder, private resetPwdService: ResetPwdService, private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      if (params['token'] === undefined) return;
      this.token = params['token'];
    });
  }

  submit() {
    if (!this.form.valid) {
      return;
    }
    this.loading = true;
    this.resetPwdService.changePassword(this.token, this.form.value.password).subscribe(
      (data) => {
        this.loading = false;
        this.router.navigate(['/login']);
      },
      (err) => {
        this.loading = false;
        if (err.isBusinessError()) {
          this.error = true;
        } else L.e(err);
      }
    );
  }
}
