// ENTITY com.ic2.entity.UserConfig generated by ic2 3.0.0-SNAPSHOT at Sun Nov 26 22:13:25 UTC 2023

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class UserConfig {

  idConfig: number | null = 0;
  idUser: number | null = 0;
  cle: string | null = '';
  valeur: string | null = '';

  constructor(init?:Partial<UserConfig>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.ic2.entity.UserConfig'] = UserConfig;

reverseMapping[UserConfig.name] = 'com.ic2.entity.UserConfig';

fields['com.ic2.entity.UserConfig']  = {
    idConfig: 'java.lang.Integer',
    idUser: 'java.lang.Integer',
    cle: 'java.lang.String',
    valeur: 'java.lang.String'
};
