<div class="container-fluid bg-light-30p h-100 pb-3" style="overflow-y: hidden">
  <div class="row mt-3" *ngIf="loading">
    <div class="col text-center"><i class="fa-light fa-spinner spinning fa-4x"></i></div>
  </div>

  <div class="row mt-3">
    <div class="col text-center">Votre email est : {{ authService.userBundle.user.email }}</div>
    <div class="col text-danger" *ngIf="!authService.userBundle.data.jeaUser.mailConfirmed">Votre email n'est pas confirmé</div>
  </div>

  <ng-container *ngIf="!loading">
    <div class="row">
      <div class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp" *ngFor="let atelier of data">
        <a class="text-body pointer" [routerLink]="['/atelier', atelier.idAtelier]">
          <div class="bg-white border">
            <div class="row g-1 pe-2">
              <div class="col-auto d-flex align-items-center">
                <i class="fa-light fa-image fa-3x text-tertiary p-4 align-icon"></i>
              </div>
              <div class="col my-fs-2 my-fs-lg-3 d-flex flex-column justify-content-center">
                <div class="row">
                  <div class="col">
                    <b>{{ atelier.name }}</b>
                  </div>
                </div>
                <div class="row">
                  <div class="col-auto my-fs-1">Type: {{ atelier.type }}</div>
                </div>
                <div class="row">
                  <div class="col-auto my-fs-1">Matière: {{ atelier.matiere }}</div>
                </div>
                <div class="row">
                  <div class="col-auto my-fs-1">Discipline: {{ atelier.discipline }}</div>
                </div>
                <div class="row">
                  <div class="col-auto my-fs-1">Public: {{ atelier.maternelle ? 'Maternelle' : '' }} {{ atelier.elementaire ? 'Élémentaire' : '' }}</div>
                </div>
                <div class="row">
                  <div class="col-auto">
                    <i class="fa-light fa-clock me-2"></i>
                    {{ atelier.dispoDate | date : 'dd/MM/yyyy HH:mm' }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-auto">
                    <i class="fa-light fa-alarm-clock me-2"></i>
                    {{ atelier.endDate | date : 'dd/MM/yyyy' }}
                  </div>
                </div>
              </div>
              <div class="col-auto text-end" *ngIf="!isFull(atelier)">
                <i class="fa-light fa-user-circle me-2" ngbTooltip="Nombre de places"></i>
                {{ atelier.nbSlotsTaken }} / {{ atelier.nbMaxSlots }}
                <br />
                <i class="fa-light fa-ship me-2" ngbTooltip="Nombre de places prêt à embarquer"></i>
                {{ atelier.nbSlotsTakenPretAEmbarquer }} / {{ atelier.nbMaxSlotsPretAEmbarquer }}
              </div>
              <div class="col-auto text-end" *ngIf="isFull(atelier)">
                <i class="fa-light fa-user-circle me-2" ngbTooltip="Nombre de places"></i>
                {{ atelier.nbSlotsTaken }} / {{ atelier.nbMaxSlots }}
                <br />
                <i class="fa-light fa-ship me-2" ngbTooltip="Nombre de places prêt à embarquer"></i>
                {{ atelier.nbSlotsTakenPretAEmbarquer }} / {{ atelier.nbMaxSlotsPretAEmbarquer }}
                <div class="text-danger">COMPLET</div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>

    <div class="row justify-content-center mt-3" *ngIf="!loading">
      <div class="col-auto">
        <ngb-pagination
          [collectionSize]="count"
          [(page)]="HomeComponent.page"
          [pageSize]="nbPerPage"
          [maxSize]="3"
          [rotate]="true"
          (pageChange)="reload()"
          [disabled]="loading"
        ></ngb-pagination>
      </div>
    </div>
  </ng-container>
</div>
