import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { AtelierComponent } from './pages/atelier/atelier.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { PolicyComponent } from './pages/policy/policy.component';
import { ResetPasswordFormComponent } from './pages/reset-password/reset-password-form/reset-password-form.component';
import { ResetPasswordMailSentComponent } from './pages/reset-password/reset-password-mail-sent/reset-password-mail-sent.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password/reset-password.component';
import { ConfirmEmailComponent } from './pages/signup/confirm-email/confirm-email.component';
import { SignupComponent } from './pages/signup/signup.component';
import { AuthGuard } from './tools/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivateChild: [AuthGuard],
    //resolve: { autolog: AutologResolverService },
    children: [
      { path: '', component: HomeComponent, data: { title: 'Accueil', icon: 'fa-light fa-home' } },
      { path: 'atelier/:idAtelier', component: AtelierComponent, data: { title: 'Atelier', icon: 'fa-light fa-cupcake' } },
    ],
  },
  { path: 'logout', component: LogoutComponent },
  { path: 'login', component: LoginComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'reset-password/confirm', component: ResetPasswordMailSentComponent },
  { path: 'reset-password/:token', component: ResetPasswordFormComponent },
  { path: 'confirm-email/:token', component: ConfirmEmailComponent },
  { path: 'policy', component: PolicyComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
