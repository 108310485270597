// Facade exporting all classes for simple loading
// use export * from "./services/services"; in your module file
export * from './AtelierService';
export * from './CoreService';
export * from './FacebookLoginService';
export * from './FrontConfigBOService';
export * from './ParticipationService';
export * from './RegisterService';
export * from './ResetPwdService';
export * from './UserSearchService';
export * from './UserService';
