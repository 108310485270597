import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EnvService, Ic2Module } from '@ic2/ic2-lib';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom } from 'rxjs';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DatetimePickerComponent } from './components/datetime-picker/datetime-picker.component';
import { UserTextassistComponent } from './components/user-textassist/user-textassist.component';
import { facebookInitializer } from './fbInit';
import { Ic2ToastrContainerComponent } from './layout/ic2-toastr-container/ic2-toastr-container.component';
import { LayoutComponent } from './layout/layout.component';
import { AtelierComponent } from './pages/atelier/atelier.component';
import { NiveauComponent } from './pages/atelier/niveau/niveau.component';
import { PretAEmbarquerComponent } from './pages/atelier/pret-a-embarquer/pret-a-embarquer.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { PolicyComponent } from './pages/policy/policy.component';
import { ResetPasswordFormComponent } from './pages/reset-password/reset-password-form/reset-password-form.component';
import { ResetPasswordMailSentComponent } from './pages/reset-password/reset-password-mail-sent/reset-password-mail-sent.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password/reset-password.component';
import { SignupComponent } from './pages/signup/signup.component';
import { AuthService } from './tools/auth.service';
import { BackDirective } from './tools/back.directive';
import { Config } from './tools/Config';
import { ConfirmEmailComponent } from './pages/signup/confirm-email/confirm-email.component';

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    LoginComponent,
    SignupComponent,
    ResetPasswordComponent,
    ResetPasswordFormComponent,
    ResetPasswordMailSentComponent,
    LogoutComponent,
    Ic2ToastrContainerComponent,
    HomeComponent,
    PolicyComponent,
    AtelierComponent,
    DatetimePickerComponent,
    BackDirective,
    NiveauComponent,
    UserTextassistComponent,
    PretAEmbarquerComponent,
    ConfirmEmailComponent,
  ],
  imports: [BrowserModule, FormsModule, ReactiveFormsModule, NgbModule, Ic2Module, AppRoutingModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (envService: EnvService<Config>, authService: AuthService) => {
        console.log('loading env for', window.location.hostname);

        if (['localhost', '192.168.1.1', 'maison.deepneel.fr'].includes(window.location.hostname)) {
          envService.confUrl = 'http://' + window.location.hostname + ':8080/front.config.json';
        } else {
          envService.confUrl = window.location.protocol + '//api.' + window.location.hostname + '/front.config.json';
        }

        return () =>
          new Promise<void>((resolve, reject) => {
            let timeout = setTimeout(() => {
              reject(new Error('Time'));
            }, 3000);

            firstValueFrom(envService.load(new Config()))
              .then((config) => {
                clearTimeout(timeout);
                resolve();
                facebookInitializer(config, authService)();
              })
              .catch(() => {
                clearTimeout(timeout);
                reject(new Error('Down'));
              });
          });
      },
      deps: [EnvService, AuthService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
