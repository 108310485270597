import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { EnvService } from '@ic2/ic2-lib';
import { Observable } from 'rxjs';
import { CoreService } from 'src/ic2/services/CoreService';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  public redirectLoginRoute = '/login';
  public backoffice = false;
  constructor(private authService: AuthService, private router: Router, private coreService: CoreService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const url: string = state.url;
    //console.log('canActivate', route, state);
    return this.check(route, url);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const url: string = state.url;
    //console.log('canActivateChild', route, state);
    return this.check(route, url);
  }
  storeRedirectUrl(url: string) {
    if (url !== '/update' && url !== '/' && url !== '/login' && url !== '/logout') {
      localStorage.setItem('redirectUrl', url);
      console.log('storing url to later redirect', url);
    }
  }
  check(route: ActivatedRouteSnapshot, url: string): Observable<boolean | UrlTree> {
    if (window.location.hash.length > 0 && window.location.hash && window.location.hash.indexOf('#token=') !== -1) {
      this.authService.irpc.authToken = window.location.hash.substring(window.location.hash.indexOf('#token=') + '#token='.length);
      this.authService.saveToken(this.authService.irpc.authToken);
      console.log('token found in url');
      window.location.hash = '';
    }
    //const url = urlWithHash; //.substring(0, urlWithHash.indexOf('#'));
    return new Observable((observer) => {
      this.authService.isLoggedIn().subscribe({
        next: (loggedIn) => {
          //console.log(this.authService.userBundle);
          //console.log('login',loggedIn);
          //console.log(route);
          if (!loggedIn) {
            // Store the attempted URL for redirecting
            //window.location.href a la place de url pour retirer le hash (bug lorsqu'on réponds a une enquete et qu'on doit valider la rgpd, le code restait)
            this.storeRedirectUrl(window.location.pathname);
            console.log('redirecting to auth url', this.redirectLoginRoute);

            observer.next(this.router.parseUrl(this.redirectLoginRoute));
          } else {
            console.log('allow access to', url);
            observer.next(true);
          }
          /*
        else if (
          this.authService.has(DegineoRight.CLIENT) &&
          !this.authService.userBundle.du.signupFinished &&
          route.routeConfig.path !== 'configuration-compte'
        ) {
          //console.log('SHOULD NAVIGATE');
          this.router.navigate(['/configuration-compte']);
        }
        */
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
        },
      });
    });
  }
}
